import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Body = makeShortcode("Body");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Body mdxType="Body">
      <p>{`Takazudo（Takeshi Takatsudo）が書いたノートが置いてあるWebサイトです。`}<br parentName="p"></br>{`
`}{`Takazudoは以下にいます。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://twitter.com/Takazudo",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Twitter: Takazudo`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.instagram.com/takazudo/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`instagram: takazudo`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.pxgrid.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`株式会社ピクセルグリッド`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.codegrid.net/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`CodeGrid`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://qiita.com/Takazudo",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Qiita: Takazudo`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.tradingview.com/u/Takazudo/#published-scripts",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`TradingView: Takazudo`}</a></li>
      </ul>
      <h2 {...{
        "id": "著書"
      }}>{`著書`}</h2>
      <p>{`書いた本です。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/33GDF5w",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`ざっくりつかむ CSS設計`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/3Ite9zy",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`改訂版 Webデザイナーのための jQuery入門`}</a></li>
      </ul>
    </Body>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      